.tbleHdrRow {
    /* min-height: 41px; */
    min-height: 30px;
    align-items: center;
}
.tbleHdrRow .dropdown > button {
    /* background-color: #E0EBFF;
    border-radius: 5px; */
    /* font-size: 25px; */
    font-size: 20px;
    color: var(--mv-color-primary);
}
.tbleHdrRow .dropdown > button.text {
    font-size: 14px;
}
.tbleHdrRow .dropdown > button img {
    width: 20px;
    height: 20px;
    object-fit: fill;
}
.tbleHdrRow .dropdown > button svg {
    margin: 0 auto;
}