.modal.passwordProfile .modal-content {
    max-width: 400px;
}
.passwordProfile .modal-content .head {
    border-bottom: 1px solid #efefef;
}
.passwordProfile .modal-content .head h3 {
    font-size: 16px;
    font-weight: 500;
    flex: 1;
    text-align: center;
    padding-left: 30px;
}
.passwordProfile .passCont {
    padding: 20px;
}
.passwordProfile .passCont label {
    color: var(--mv-color-muted);
    font-size: 12px;
    position: relative;
}
.passwordProfile .passCont label input {
    display: block;
    border: 1px solid #80ABFF;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    padding: 10px 50px 10px 15px;
    font-size: 14px;
    color: #000;
    margin: 5px 0 0;
}
.passwordProfile .passCont label button {
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #5E5E5E;
}
.passwordProfile .passCont label button * {
    pointer-events: none;
}
.passwordProfile .passCont > button {
    padding: 12px 30px;
    background-color: var(--mv-color-primary);
    color: #fff;
    font-weight: 600;
    border-radius: 30px;
    margin: 30px auto 0;
    display: block;
    min-width: 170px;
}

.passwordProfile .passCont .success-box {
    height: 250px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    gap: 10px 0;
}
.passwordProfile .passCont .success-box svg {
    font-size: 40px;
    color: #19B64E;
}

.passwordProfile .profileDropArea {
    border: 2px dashed #D9D9D9;
    padding: 10px 20px;
    border-radius: 8px;
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: 0.2s ease-in;
    text-align: center;
}
.passwordProfile .profileDropArea svg {
    font-size: 30px;
}
.passwordProfile .profileDropArea svg.uploadIcon {
    color: #0095FF;
}
.passwordProfile .profileDropArea svg.checkIcon {
    color: #19B64E;
}
.passwordProfile .profileDropArea.dropping {
    background-color: #D9D9D9;
}
.passwordProfile label[for="profileUpload"] {
    width: 170px;
    border: 1px solid var(--mv-color-primary);
    padding: 12px 30px;
    text-align: center;
    border-radius: 30px;
    font-weight: 600;
    font-size: 14px;
    color: var(--mv-color-primary);
    margin: 0 auto;
    cursor: pointer;
}
.passwordProfile .passCont > button.disabled {
    opacity: 0.5;
    cursor: no-drop;
}