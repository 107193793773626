:root {
  --mv-TMSidebar-width: 80px;
  --mv-TMSidebar-width-open: 270px;
  --mv-TMSidebar-width-adjusted: var(--mv-TMSidebar-width-open);
}

.sidebar {
  border-right: 2px solid #e7e8ea;
}
.sidebar.tm .projInfo {
  text-align: center;
}
.sidebar.tm .projInfo img {
  width: 50px;
  height: 50px;
  margin: 0 auto;
}
.sidebar.tm .close {
  position: absolute;
  /* right: -40px; */
  right: -50px;
}
.sidebar.tm:not(.open) .close {
  transform: scaleX(-1);
}

.sidebar.tm {
  /* top: 81px; */
  top: var(--mv-navigation-height);
  width: var(--mv-TMSidebar-width);
  padding-top: 15px;
  transition: 0.3s ease-in-out;
  z-index: 9;
  --mv-TMSidebar-width-adjusted: var(--mv-TMSidebar-width);
}

.sidebar.tm.h-screen {
  /* height: calc(100vh - 81px); */
  height: calc(100vh - var(--mv-navigation-height));
}

.sidebar.tm.open {
  /* width: 270px; */
  width: var(--mv-TMSidebar-width-open);
  --mv-TMSidebar-width-adjusted: var(--mv-TMSidebar-width-open);
}
.sidebar.tm.open .projInfo img {
  /* width: 70px;
    height: 70px; */
  width: 240px;
  height: 85px;
  object-fit: contain;
}

.sidebar.tm .scroll {
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.sidebar.tm .projInfo {
  text-align: center;
  padding: 0 10px;
}
.sidebar.tm.open .projInfo {
  padding: 0 30px;
}
.sidebar.tm.open .projInfo > div {
  opacity: 1;
  visibility: visible;
  margin: 20px 0 70px;
}
.sidebar.tm .projInfo > div {
  border-top: 2px solid #b1b3b4;
  margin: 20px 0 70px;
  opacity: 0;
  width: 210px;
  /* height: 102px; */
  height: 132px;
  margin: 20px 0 20px;
  /* transition: margin 0.3s, opacity 0.4s; */
  transition: 0.3s ease-in-out;
}
.sidebar.tm .projInfo > div h2 {
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0 15px;
}
.sidebar.tm .projInfo > div div span {
  /* font-size: 20px; */
  font-size: 14px;
  font-weight: 500;
  color: #4c4dff;
  text-align: center;
}
.sidebar.tm .projInfo > div div span small {
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: #000;
  min-height: 40px;
}
.sidebar.tm .projInfo > div div span span {
  display: block;
}
.sidebar.tm .projFeaturesCircle {
  text-align: center;
  position: relative;
  transition: 0.3s ease-in-out;

  width: 113px;
  height: 113px;
  margin: 0 auto;
}
.sidebar.tm:not(.open) .projFeaturesCircle {
  width: 50px;
  height: 50px;
  margin: 0 auto 163px;
}
.sidebar.tm .projFeaturesCircle .circle {
  width: 113px;
  height: 113px;
  margin: 0 auto;
  /* position: relative; */
  z-index: 1;
  transition: 0.3s ease-in-out;

  position: absolute;
}
.sidebar.tm:not(.open) .projFeaturesCircle .circle {
  width: 50px;
  height: 50px;
}
.sidebar.tm .projFeaturesCircle button.feature {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  min-width: 10px;
  max-width: 60px;
  text-align: left;
  transition: 0.5s ease-in-out;
  /* inset: 0;
    margin: auto; */
  transform-origin: 57px 57px;
}
.sidebar.tm .projFeaturesCircle button.feature > * {
  pointer-events: none;
}
.sidebar.tm:not(.open) .projFeaturesCircle button.feature span {
  display: none;
}
.sidebar.tm .projFeaturesCircle button.feature::before {
  content: "";
  width: 13px;
  height: 13px;
  position: absolute;
  border-radius: 10px;
  transition: 0.5s ease-in-out;
}
.sidebar.tm:not(.open) .projFeaturesCircle button.feature::before {
  width: 8px;
  height: 8px;
}
/* One active */
.sidebar.tm .projFeaturesCircle.one-active button.feature.one {
  transform: translate(67px, 50px);
}
.sidebar.tm .projFeaturesCircle.one-active button.feature.two {
  transform: translate(-27px, -40px);
}
.sidebar.tm .projFeaturesCircle.one-active button.feature.three {
  transform: translate(-104px, 82px);
}
/* One active */
/* Two active */
.sidebar.tm .projFeaturesCircle.two-active button.feature.one {
  transform: translate(-104px, 82px);
}
.sidebar.tm .projFeaturesCircle.two-active button.feature.one::before {
  transform: translate(53px, 0);
}
.sidebar.tm .projFeaturesCircle.two-active button.feature.two {
  transform: translate(67px, 40px);
}
.sidebar.tm .projFeaturesCircle.two-active button.feature.two::before {
  transform: translate(-17px, 9px);
}
.sidebar.tm .projFeaturesCircle.two-active button.feature.three {
  transform: translate(-25px, -25px);
}
.sidebar.tm .projFeaturesCircle.two-active button.feature.three::before {
  transform: translate(19px, 18px);
}
/* Two active */
/* Three active */
.sidebar.tm .projFeaturesCircle.three-active button.feature.one {
  transform: translate(-25px, -25px);
}
.sidebar.tm .projFeaturesCircle.three-active button.feature.one::before {
  transform: translate(19px, 18px);
}
.sidebar.tm .projFeaturesCircle.three-active button.feature.two {
  transform: translate(-100px, 79px);
}
.sidebar.tm .projFeaturesCircle.three-active button.feature.two::before {
  transform: translate(47px, 2px);
}
.sidebar.tm .projFeaturesCircle.three-active button.feature.three {
  transform: translate(67px, 50px);
}
.sidebar.tm .projFeaturesCircle.three-active button.feature.three::before {
  transform: translate(-17px, 1px);
}
/* Three active */

.sidebar.tm:not(.open) .projFeaturesCircle button.feature.one {
  /* right: -4px; */
  transform: translate(37px, 20px);
}

.sidebar.tm .projFeaturesCircle button.feature:disabled::before {
  background-color: #a29a9a !important;
}

.sidebar.tm .projFeaturesCircle button.feature.one::before {
  transform: translate(-17px, 1px);
  background-color: #6b0086;
}

.sidebar.tm:not(.open) .projFeaturesCircle button.feature.two {
  /* top: -12px; */
  transform: translate(-25px, -38px);
}
.sidebar.tm .projFeaturesCircle button.feature.two::before {
  transform: translate(22px, 34px);
  background-color: #ffc662;
}

.sidebar.tm:not(.open) .projFeaturesCircle button.feature.three {
  transform: translate(-74px, 39px);
}
.sidebar.tm .projFeaturesCircle button.feature.three::before {
  transform: translate(53px, 0);
  background-color: #4c4dff;
}

/* Close one active */
/* Close one active */
/* Close two active */
.sidebar.tm:not(.open) .projFeaturesCircle.two-active button.feature.one {
  transform: translate(-78px, 35px);
}
.sidebar.tm:not(.open) .projFeaturesCircle.two-active button.feature.two {
  transform: translate(38px, 12px);
}
.sidebar.tm:not(.open) .projFeaturesCircle.two-active button.feature.three {
  transform: translate(-23px, -23px);
}
/* Close two active */
/* Close Three active */
.sidebar.tm:not(.open) .projFeaturesCircle.three-active button.feature.one {
  transform: translate(-23px, -23px);
}
.sidebar.tm:not(.open) .projFeaturesCircle.three-active button.feature.two {
  transform: translate(-72px, 35px);
}
.sidebar.tm:not(.open) .projFeaturesCircle.three-active button.feature.three {
  transform: translate(38px, 17px);
}
/* Close Three active */

.sidebar.tm > div > ul {
  width: calc(100% - 30px);
  margin-top: 30px;
}
.sidebar.tm:not(.open) > div > ul {
  width: 100%;
  overflow: hidden;
}
.sidebar.tm > div > ul button {
  font-size: 16px;
  display: flex;
  /* flex-wrap: wrap; */
  gap: 0 15px;
  width: 100%;
  align-items: center;
  padding: 8px 30px;
  position: relative;
  margin: 2px 0;
  width: 245px;
}
.sidebar.tm:not(.open) > div > ul button {
  padding: 8px 25px;
}
.sidebar.tm:not(.open) > div > ul button span {
  display: none;
}
.sidebar.tm > div > ul button img {
  width: 30px;
  height: 30px;
  object-fit: scale-down;
}
.sidebar.tm > div > ul button::before,
.sidebar.tm > div > ul button::after {
  content: "";
  position: absolute;
  height: 100%;
  left: 0;
  z-index: -1;
}
.sidebar.tm > div > ul button::before {
  width: 0;
  background-color: #d5dff2;
  border-radius: 0 5px 5px 0;
  transition: 0.2s ease-in-out;
}
.sidebar.tm:not(.open) > div > ul button::before {
  display: none;
}
.sidebar.tm > div > ul button.active::before,
.sidebar.tm > div > ul button:hover::before {
  width: 100%;
}
.sidebar.tm > div > ul button::after {
  width: 5px;
  background-color: #4c4dff;
  border-radius: 0 10px 10px 0;
  opacity: 0;
}
.sidebar.tm > div > ul button.active::after {
  opacity: 1;
}
.sidebar.loading {
  pointer-events: none;
}
