.chatbot_btn {
    position: fixed;
    /* right: 60px;
    bottom: 60px; */
    right: 50px;
    bottom: 40px;
    width: 80px;
    height: 80px;
    top: auto;
    border: 0;
    border-radius: 50%;
    background: linear-gradient(131.88deg, #80ABFF 8.16%, #1140B5 95.92%);
    box-shadow: 0px 4px 192px rgba(0, 0, 0, 0.25);
}
.chatbot_btn img {
    width: 50px;
    margin: 0 8px 0 0;
}
.chatbot_box {
    /* background-color: #f4f4f4; */
}
.chatbot_box {
    position: absolute;
    right: 10px;
    bottom: 90px;
    /* width: 0;
    height: 0;
    max-width: 390px;
    max-height: 450px; */
    /* width: 390px;
    height: 450px; */
    /* background: #FFFFFF; */
    /* background: #f4f4f4; */
    /* background-color: #f4f4f4 !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 55px 25px 25px 25px;
    /* transition: 0.2s ease-in-out; */
    /* transform-origin: bottom right; */
}
.chatbot_box.active {
    /* width: 390px;
    height: 450px; */
    transition: 0.2s ease-in-out;
    transform: scale(1);
}
/* Add these styles to your CSS */
.chatbot-box .circle {
    width:18px;
    height: 18px;
    background-color: #3CD26F; /* Choose your desired color */
    border-radius: 100%;
    position: absolute;
    top: 0px;
    right: 7px;
  }
  
.chatbot_box .close {
    color: red;
}
.chatbot_box > img.logo {
    display: block;
    margin: 50px auto 40px calc(50% - 50px);
    width: 95px;    
}
.chatbot_box > img.logo.xs {
    width: 53px;
    margin: 10px 0 20px;
    transition: 0.2s ease-in-out;
}
.chatbot_box .before_chat {
    text-align: center;
    position: relative;
}
.chatbot_box h4 {
    font-size: 20px;
    margin: 0 0 15px;
}
.chatbot_box p {
    font-size: 15px;
    line-height: 19px;
    color: #5b5b5b;
    padding: 0 10px;
    margin: 0;
}


.chatbot_box .before_chat .steps {
    position: relative;
    height: 139px;
    overflow: hidden;
}
.chatbot_box .before_chat .step {    
    position: absolute;
    width: 340px;
    left: 0;
    transition: 0.2s ease-in-out;
}
.chatbot_box .before_chat .step.prev {
    left: -340px;
    opacity: 0;
}
.chatbot_box .before_chat .step.active ~ .step {
    left: 340px;
    opacity: 0;
}


.chatbot_box .before_chat .steps_dots {
    display: flex;
    flex-wrap: wrap;
    gap: 0 8px;
    justify-content: center;
    margin: 25px 0;
}
.chatbot_box .before_chat .steps_dots span {
    width: 10px;
    height: 10px;
    background-color: #D9D9D9;
    border-radius: 50%;
    opacity: 0.6;
}
.chatbot_box .before_chat .steps_dots span.active {
    opacity: 1;
}

.chatbot_box .before_chat .navigation_btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
}
.chatbot_box .before_chat .navigation_btns button {
    min-width: 48px;
    line-height: 48px;
    border: 0;
    border-radius: 40px;
    background: #D9D9D9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
    font-size: 20px;
    padding: 0;
    color: #737373;
}
.chatbot_box .before_chat .navigation_btns button > * {
    pointer-events: none;
}
.chatbot_box .before_chat .navigation_btns .getStarted {
    background-color: #19B64E;
    color: #fff;
    font-size: 15px;
    padding: 0 20px;
    min-width: 180px;
    position: absolute;
    right: 0;
}


.chat_room .auto_commands,
.chat_room .chat_nodes {
    height: calc(230px);
    margin-bottom: 10px;
}
 .chat_room .auto_commands p {
    padding: 0;
    margin: 0 0 15px;
}
.auto_commands .autoMsg {
    font-size: 12px;
    border: 0;
    padding: 10px 15px;
    margin: 0 5px 5px 0;
    border-radius: 40px;
    background: rgba(70, 102, 217, 0.25);
    color: #3a3a3a;
}


.typing_area {
    display: flex;
    flex-wrap: wrap;
    gap: 0 15px;
    padding: 10px 20px;
}
.typing_area #userMsg::placeholder {
    color: #7e7e7e;
    opacity: 1;
}
.typing_area .textareaWrp {
    display: inline-flex;
    align-items: center;
    width: calc(100% - 55px);
    padding: 5px;
    background-color: #fff;
    border-radius: 20px;
}
.typing_area .textareaWrp button {
    font-size: 20px;
    padding: 0 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.typing_area .textareaWrp button.active {
    color: var(--mv-color-primary);
}
.typing_area #userMsg {
    padding: 7px 10px;
    height: 36px;
    max-height: 100px; /* Allow it to grow up to 60px */
    overflow-y: auto; /* Hide scrollbar until max-height is reached */
    border: 0;
    background-color: #ffffff;
    /* border-radius: 20px; */
    color: #7e7e7e;
    resize: none;
    font-size: 14px;
    width: 100%;
    box-shadow: none;
    outline: none;
    margin: 0;
}
/* Styling the scrollbar in Firefox  */
.typing_area #userMsg {
    scrollbar-width: thin;
}
  
/* Styling the scrollbar in Chrome and Safari */
.typing_area #userMsg::-webkit-scrollbar {
    width: 5px;
}
 .typing_area button.sendMsg {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: 0;
    background: #4C4DFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #fff;
    font-size: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
 .typing_area button.sendMsg > * {
    pointer-events: none;
}
 .chat_nodes {
    height: calc(279px);
    margin-bottom: 10px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px 0; */
}
.chat_room .chat_nodes > div {
    max-width: 70%;
    padding: 7px 10px;
    border-radius: 13px;
    font-size: 12px;
    line-height: 16px;
    width: fit-content;
}
.chat_room .chat_nodes > div:not(:last-child) {
    margin-bottom: 10px;
}
.chat_room .chat_nodes .userNode {
    margin-left: auto;
    background: rgba(70, 102, 217, 0.25);
    border-bottom-right-radius: 0;
}
 .chat_room .chat_nodes .botNode {
    margin-right: auto;
    background: #9c9eff;
    color: #fff;
    border-bottom-left-radius: 0;
}
/* Virtual Assistant */
.virtual-assistant {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .virtual-assistant.active {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.va-chat {
    position: absolute;
    /* right: -25px;
    bottom: -256px; */
    right: 0;
    bottom: 0;
    /* width: 0;
    height: 0;
    max-width: 390px;
    max-height: 450px; */
    /* width: 390px;
    height: 450px; */
    width: 390px;
    height: 450px;
     /* background: #ffffff; */
     background: #f4f4f4;
    border-radius: 10px;
    /* padding: 10px 20px; */
    padding: 1px 1px 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: 0.2s ease-in-out;
}
.va-chat .chat-head {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}
.va-chat .chat-head button {
    font-size: 16px;
}

.chatbot-box.maximized .va-chat {
    height: 60vh;
    width: 550px;
    top: 230px;
}

.chatbot_box .auto_commands {
    padding: 1rem 1.25rem 1rem 1.25rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 #00000014;
}

.chatbot_box .auto_commands .aiAmin + div {
    width: calc(100% - 58px);
}
.chatbot-box.maximized .chatbot_box {
    bottom: 0;
    top: 0;
}
.chatbot-box.maximized {
    top: 0;
    bottom: 0;
}
  
.va-messages {
    flex-grow: 1;
    overflow-y: auto;
    /* padding: 1em; */
    padding: 1em 1.25rem;
    scrollbar-width: none;
    text-align: left;
    display: flex;
    flex-direction: column;
  }
  
.va-message {
    /* padding: 1em; */
    max-width: 70%;
    /* box-shadow: 0px 0px 10px rgba(70, 102, 217, 0.15); */
    margin-bottom: 1em;
    /* border-radius: 10px; */
    font-family: "Roboto", sans-serif;

    display: flex;
    flex-wrap: wrap;
    gap: 0 10px;
}

.va-message .avatar {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 5px 0 #00000045;
    padding: 2px;
    overflow: hidden;
    margin-top: 5px;
}

.va-message .avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.va-message div {
    width: fit-content;
    max-width: calc(100% - 35px);
    box-shadow: 0px 0px 10px rgba(70, 102, 217, 0.15);
    border-radius: 0 10px 10px 10px;
    padding: 10px;
}
.va-message button.dislikeResponse {
    margin-left: 38px;
    margin-top: 5px;
    opacity: 0;
}
.va-message button.dislikeResponse * {
    pointer-events: none;
}
.va-message:hover button.dislikeResponse {
    opacity: 1;
}
.va-message:hover button.dislikeResponse.active {
    color: var(--mv-color-primary);
}
.va-messages .va-message.assistant:first-child button.dislikeResponse {
    display: none;
}

.va-message.user {
    align-self: flex-end;
    /* flex-direction: column-reverse; */
}

.va-message.user div {
    border-radius: 10px 0 10px 10px;
    background-color: #fff;
}
  
  .user {
    /* align-self: flex-end; */
    /* background-color: #4c4dff; */
    /* color: #fff; */
  }
  
  .assistant {
    align-self: flex-start;
    /* background-color: #585959; */
    /* color: #fff; */
  }
  
  .va-input {
    display: flex;
    justify-content: space-between;
    padding: 0.5em;
    background: #403832;
    color: white;
  }
  
  .va-input input {
    flex: 1;
    border: none;
    background: none;
    color: white;
    padding: 0.5em;
    font-family: "Roboto", sans-serif;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  
  .va-input button {
    background: #4c4dff;
    color: #fff;
    border: none;
    padding: 0.5em 1em;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
  }
  
  .va-input button:focus {
    outline: none;
  }
  
  .va-input button:active {
    transform: translateY(2px);
  }
