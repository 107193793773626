:root {
    --mv-version-baseline: linear-gradient(135deg, #CE9FFC 0%, #7367F0 100%);
    --mv-version-v1: linear-gradient(135deg, #FF9D6C 0%, #BB4E75 100%);
    --mv-version-v2: linear-gradient(135deg, #70F570 0%, #49C628 100%);
    --mv-version-v3: linear-gradient(135deg, #79F1A4 0%, #0E5CAD 100%);
    --mv-version-v4: linear-gradient(135deg, #FFE985 0%, #FA742B 100%);
    --mv-version-v5: linear-gradient(135deg, #F05F57 0%, #360940 100%);
    --mv-version-v6: linear-gradient(135deg, #81FBB8 0%, #28C76F 100%);
    --mv-version-v7: linear-gradient(135deg, #81FFEF 0%, #F067B4 100%);
    --mv-version-v8: linear-gradient(135deg, #FDD819 0%, #E80505 100%);
    --mv-version-v9: linear-gradient(135deg, #EECE13 0%, #B210FF 100%);
    --mv-version-v10: linear-gradient(135deg, #EECE13 0%, #2010ff 100%);
}

.v-bl {
    background: var(--mv-version-baseline);
}
.v-1,
.v-V1 {
    background: var(--mv-version-v1);
}
.v-2,
.v-V2 {
    background: var(--mv-version-v2);
}
.v-3,
.v-V3 {
    background: var(--mv-version-v3);
}
.v-4,
.v-V4 {
    background: var(--mv-version-v4);
}
.v-5,
.v-V5 {
    background: var(--mv-version-v5);
}
.v-6,
.v-V6 {
    background: var(--mv-version-v6);
}
.v-7,
.v-V7 {
    background: var(--mv-version-v7);
}
.v-8,
.v-V8 {
    background: var(--mv-version-v8);
}
.v-9,
.v-V9 {
    background: var(--mv-version-v9);
}
.v-10,
.v-V10 {
    background: var(--mv-version-v10);
}

.workingArea .title-version .dropdown.proVerDrp {
    margin-top: -10px;
}

.workingArea .title-version .dropdown.proVerDrp > button {
    gap: 0 5px;
}
.workingArea .title-version .dropdown.proVerDrp > button svg {
    color: #000;
    font-size: 18px;
}
.workingArea .title-version .dropdown.proVerDrp > button span {
    min-width: 32px;
    min-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 800;
    border-radius: 40px;
    color: #fff;
}

.workingArea .title-version .dropdown.proVerDrp .dd-menu {
    min-width: 165px;
    left: 0;
}
.workingArea .title-version .dropdown.proVerDrp .dd-menu button {
    font-size: 14px;
    padding: 6px 15px;
}
.workingArea .title-version .dropdown.proVerDrp .dd-menu button:hover {
    background-color: #E0EBFF;
}
.workingArea .title-version .dropdown.proVerDrp .dd-menu button span {
    width: 32px;
    height: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 800;
    color: #fff;
    border-radius: 30px;
}