.modal {
    position: fixed;
    inset: 0;
    background-color: rgba(150, 149, 149, 0.50);
    z-index: 99;
    display: none;
    overflow-y: auto;
}
.fade {
    opacity: 0;
    transition: opacity .15s linear;
}
.fade.show {
    opacity: 1;
}
.modal.fade .modal-content {
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out,-webkit-transform .3s ease-out;
    -webkit-transform: translate(0,10%);
    transform: translate(0,10%);
}
.modal.show .modal-content {
    -webkit-transform: translate(0,0);
    transform: translate(0,0);
}

.modal-content {
    max-width: 500px;
    /* margin: 1.75rem auto; */
    margin: 60px auto;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: 0 3px 3px 0 #00000026;
    border-radius: .6rem;
    outline: 0;
    /* min-height: 400px; */
}
.modal-content.md {
    max-width: 1000px;
}
.modal-content.lg {
    max-width: 1400px;
}
.modal-content.full {
    max-width: 100%;
    /* width: fit-content; */
    width: calc(100% - 60px);
}
.modal-content.hfull {
    min-height: calc(100vh - 120px);
}
.modal-content > div {
    padding: 15px 20px;
}
.modal-content .head {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 0 20px;
}
.modal-content .head h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
}
.modal-content .head button {
    font-size: 20px;
    color: #FF5840;
    position: relative;
    z-index: 9;
}