/* loading bar */
:root {
  --mv-loading-bar-width: 0;
}

.TMCoreLoading::before {
  content: "";
  position: fixed;
  width: var(--mv-loading-bar-width);
  height: 5px;
  background-color: var(--mv-color-primary);
  top: 0;
  left: 0;
  transition: width 1s ease-in;
}
/* loading bar */

/* capibility bar */
:root {
  --mv-capability-name-height: 20px;
  --mv-capability-name-width: 83px;
  --blur-width: 200px;
  --blur-size: var(--blur-width) calc(100vh - var(--mv-navigation-height));

  --capability-name-height: 20px;
  --capability-name-width: 85px;
  --capability-name-skeleton: linear-gradient(#e9e9e9 var(--capability-name-height), transparent 0);
  --capability-name-position: 30px 4px;

  --baseline-circle-height: 32px;
  --baseline-circle-width: 32px;
  --baseline-circle-skeleton: linear-gradient(#e9e9e9 var(--baseline-circle-height), transparent 0);
  --baseline-circle-position: 121px -2px;

  --baseline-height: 20px;
  --baseline-width: 120px;
  --baseline-skeleton: linear-gradient(#e9e9e9 var(--baseline-height), transparent 0);
  --baseline-position: 160px 4px;
  
  --add-file-height: 20px;
  --add-file-width: 85px;
  --add-file-skeleton: linear-gradient(#e9e9e9 var(--add-file-height), transparent 0);
  --add-file-position: calc(100% - 140px) 20px;

  --add-members-height: 25px;
  --add-members-width: 55px;
  --add-members-skeleton: linear-gradient(#e9e9e9 var(--add-members-height), transparent 0);
  --add-members-position: calc(100% - 35px) 18px;

  --add-membersbtn-height: 25px;
  --add-membersbtn-width: 25px;
  --add-membersbtn-skeleton: linear-gradient(#e9e9e9 var(--add-membersbtn-height), transparent 0);
  --add-membersbtn-position: 100% 18px;

  --breadcrumb-height: 21px;
  --breadcrumb-width: 100px;
  --breadcrumb-skeleton: linear-gradient(#e9e9e9 var(--breadcrumb-height), transparent 0);
  --breadcrumb-position: 0 37px;
}
/* capibility bar end */

/* feature bar */
:root {
  --featureTab-height: 40px;
  --featureTab-width: 40px;
  --featureTab-skeleton: linear-gradient(#e9e9e9 var(--featureTab-height), transparent 0);
  --featureTab1-position: 0 105px;

  --featureTab2-position: 75px 105px;

  --tool-height: 30px;
  --tool-width: 30px;
  --tool-skeleton: linear-gradient(#e9e9e9 var(--tool-height), transparent 0);

  --tool1-position: calc(100% - 50px) 105px;
  --tool2-position: 100% 105px;
}
/* feature bar end */

/* work area */
:root {
  --main-card-height: calc(100% - 120px);
  --main-card-width: 100%;
  --main-card-skeleton: linear-gradient(#e9e9e9e7 var(--main-card-height), transparent 0);
  --main-card-position: 0 160px;

  --cardbtn-height: 40px;
  --cardbtn-width: 40px;
  --cardbtn-skeleton: linear-gradient(#696969 var(--cardbtn-height), transparent 0);
  --cardbtn-position: calc(100% - 20px) 180px;

  --cardbtn2-position: calc(100% - 75px) 180px;

  --addrowbtn-height: 30px;
  --addrowbtn-width: 160px;
  --addrowbtn-skeleton: linear-gradient(#696969 var(--addrowbtn-height), transparent 0);
  --addrowbtn-position: 20px calc(var(--main-card-height) + 20px);
}
/* work area end */

.TMCoreLoading {
  width: calc(100% - var(--mv-container-padding-inline)*2);
  height: calc(100vh - var(--mv-navigation-height));
  position: absolute;
  top: -3px;
  left: var(--mv-container-padding-inline);
  background-color: var(--mv-TM-bgColor);
  /* background-color: red; */
  z-index: 9;
  opacity: 0.9;
}

.TMCoreLoading ~ div {
  opacity: 0;
}

.TMCoreLoading::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg,
      rgba(211, 211, 211, 0) 0,
      rgba(255, 255, 255, .5) 50%,
      rgba(211, 211, 211, 0) 100%),
      var(--capability-name-skeleton),
      var(--baseline-circle-skeleton),
      var(--baseline-skeleton),
      var(--add-file-skeleton),
      var(--add-members-skeleton),
      var(--add-membersbtn-skeleton),
      var(--breadcrumb-skeleton),
      var(--featureTab-skeleton),
      var(--featureTab-skeleton),
      var(--tool-skeleton),
      var(--tool-skeleton),
      var(--main-card-skeleton),
      var(--cardbtn-skeleton),
      var(--cardbtn-skeleton),
      var(--addrowbtn-skeleton);
  background-size:
    var(--blur-size),
    var(--capability-name-width) var(--capability-name-height),
    var(--baseline-circle-width) var(--baseline-circle-height),
    var(--baseline-width) var(--baseline-height),
    var(--add-file-width) var(--add-file-height),
    var(--add-members-width) var(--add-members-height),
    var(--add-membersbtn-width) var(--add-membersbtn-height),
    var(--breadcrumb-width) var(--breadcrumb-height),
    var(--featureTab-width) var(--featureTab-height),
    var(--featureTab-width) var(--featureTab-height),
    var(--tool-width) var(--tool-height),
    var(--tool-width) var(--tool-height),
    var(--main-card-width) var(--main-card-height),
    var(--cardbtn-width) var(--cardbtn-height),
    var(--cardbtn-width) var(--cardbtn-height),
    var(--addrowbtn-width) var(--addrowbtn-height);
  background-position:
    -150% 0,
    var(--capability-name-position),
    var(--baseline-circle-position),
    var(--baseline-position),
    var(--add-file-position),
    var(--add-members-position),
    var(--add-membersbtn-position),
    var(--breadcrumb-position),
    var(--featureTab1-position),
    var(--featureTab2-position),
    var(--tool1-position),
    var(--tool2-position),
    var(--main-card-position),
    var(--cardbtn-position),
    var(--cardbtn2-position),
    var(--addrowbtn-position);
  background-repeat: no-repeat;
  animation: loading 2.5s infinite;
}

@keyframes loading {
  to {
    background-position:
      200% 0,
      var(--capability-name-position),
      var(--baseline-circle-position),
      var(--baseline-position),
      var(--add-file-position),
      var(--add-members-position),
      var(--add-membersbtn-position),
      var(--breadcrumb-position),
      var(--featureTab1-position),
      var(--featureTab2-position),
      var(--tool1-position),
      var(--tool2-position),
      var(--main-card-position),
      var(--cardbtn-position),
      var(--cardbtn2-position),
      var(--addrowbtn-position);
  }
}

.loader-cont p {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 0;
}