@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Roboto';
  src: url(../public/fonts/Roboto-Light.woff2) format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src: url(../public/fonts/Roboto-Regular.woff2) format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url(../public/fonts/Roboto-Medium.woff2) format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url(../public/fonts/Roboto-Bold.woff2) format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: 'Michroma';
  src: url(../public/fonts/Michroma-Regular.woff2) format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Microgramma';
  src: url(../public/fonts/Microgramma-D-Extended-Bold.woff2) format('woff2');
  font-weight: 700;
}

/* theme colors */
:root {
  --mv-color-primary: #4C4DFF;
  --mv-color-secondary: #BDE3FF;
  --mv-color-yellow: #FFC662;
  --mv-color-lightYellow: #FFC662;
  --mv-color-red: #FB4B4B;
  --mv-color-reddish: #FF5840;
  --mv-color-green: #47DEC3;
  --mv-color-lightBlue: #E0EBFF;
  --mv-color-primaryText: #1E1E1E;
  --mv-color-muted: #858585;
  --mv-border-color: #cdd2dd;
}
/* theme colors end */

/* utils */
:root {
  --mv-container-padding-inline: 1.5rem;
}
/* utils */

/* main loader */
.main-loader {
  position: fixed;
  inset: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  z-index: 10000;
}
.main-loader img {
  /* width: 200px; */
}
/* main loader */

body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  color: #1E1E1E;
  overflow-x: hidden;
}

input, select {
  outline: none !important;
}

.container {
  max-width: 100%;
  padding: 0 var(--mv-container-padding-inline);
  position: relative;
}

.v-scroller {
  max-height: calc(100vh - 252px);
  min-height: calc(100vh - 252px);
  overflow-y: auto;
  height: 100%;
  padding-bottom: 50px;
  margin-right: -1.5rem;
  padding-right: 1.5rem;
}
.v-scroller.h193 {
  /* max-height: calc(100vh - 193px); */
  max-height: calc(100vh - 168px);
}

.content {
  padding: 30px 30px 30px 100px;
}

#sidebarTooltip {
  line-height: 1;
  font-size: 12px;
}

.btn {
  border-radius: 5px;
  min-width: 40px;
  min-height: 41px;
  background-color: #E0EBFF;
}
.btn.icon {
  border-radius: 5px;
  min-width: auto;
  min-height: auto;
  background: transparent;
  /* font-size: 25px; */
  font-size: 20px;
  padding: 4px;
}
.btn.icon:hover {
  background-color: #dfdfdf;
}
.btn.icon img {
  /* width: 25px;
  height: 25px; */
  width: 20px;
  height: 20px;
}
.btn img {
  margin: 0 auto;
}
.btn.md {
  border-radius: 10px;
}

.disabled {
  pointer-events: none;
  opacity: 0.8;
}

.badge {
  padding: 5px 10px;
  background-color: #ccc;
  border-radius: 20px;
}

.badge-success {
  color: #47DEC3;
  background-color: RGBA(71, 222, 195, 0.3) !important;
}

.badge-danger {
  color: #FF5840;
  background-color: RGBA(255, 88, 64, 0.3) !important;
}

.badge-delayed {
  color: #ED7D43;
  background-color: RGBA(237, 125, 67, 0.23) !important;
}

label {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}
label:last-child {
  margin: 0;
}
input[type="text"],
input[type="number"],
input[type="date"],
textarea {
  width: 100%;
  background-color: #eee;
  padding: 8px 15px;
  margin-top: 5px;
  border-radius: 5px;
}

.labelMt-0 label {
  margin: 0;
}

/* dropdown */
.items-group {
  cursor: pointer;
  list-style: none;
}

.items-group:not(.active) .items {
  display: none;
}

.items-group .items {
  position: absolute;
  right: 0;
  left: auto;
  background-color: #fff;
  top: calc(100% + 10px);    
  border-radius: 10px;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.10));
  min-width: 10rem;
  padding: .5rem 0;
  z-index: 1;
}

.items-group .items.btnDropdown {
  background-color: #E0EBFF;
}

.items-group .items.btnDropdown > :not(button) {
  display: flex;
  flex-wrap: wrap;
  gap: 0 10px;
  padding: 10px 15px;
  margin: 0;
  border-bottom: 1px solid #6F6F6F30;  
  font-size: 12px;
  font-weight: 500;
}
.items-group .items.btnDropdown > button {
  width: fit-content;
  margin: 10px auto 0;
  display: block;
  padding: 7px 10px;
  background-color: #4C4DFF;
  min-width: 100px;
  color: #fff;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 500;
}
/* dropdown */

.workingArea {
  padding: 15px 0 0 80px;
  transition: 0.3s ease-in-out;
  height: calc(100vh - 82px);
}
.workingArea.expended {
  padding: 15px 0 0 270px;
}

/* Modal related css */
.select2.full-w {
  width: 100%;
}
.select2.user-select div.selUser {
  padding: 5px 10px 5px 5px;
  border-radius: 40px;
}
.select2.user-select .css-3w2yfm-ValueContainer {
  gap: 5px;
  padding-block: 5px;
}
.modal .assignedUserList > li {
  padding: 5px 10px;
  background-color: #F0F0F0;
  border-radius: 10px;
}
.modal .assignedUserList > li img {
  width: 30px;
  height: 30px;
  border-radius: 40px;
}
.modal .assignedUserList > li > div:not(.access) {
  flex: 1;
}
.modal .assignedUserList > li .name {
  font-size: 14px;
  font-weight: 600;
}
.modal .assignedUserList > li .email {
  font-size: 12px;
}
.modal .accessType > p {
  text-transform: capitalize;
  margin-block: 10px 5px;
}
.modal .accessType > div {
  display: flex;
  flex-wrap: wrap;
  gap: 0 10px;
  align-items: center;
}
.modal .accessType > div span {
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background-color: #f0f0f0;
  border-radius: 40px;
}
.modal .accessType > div .text h5 {
  font-weight: 500;
}
.modal .accessType > div .text p {
  font-size: 12px;
}
/* .modal  .dropdown.accessTypeDD .dd-menu button {
  font-size: 14px;
}
.modal .dropdown.accessTypeDD .dd-menu li > button:hover {
  background-color: var(--mv-color-lightBlue);
}
.modal .dropdown.accessTypeDD .dd-menu > button {
  color: inherit;
  width: 100%;
  text-align: left;
  padding: 10px 15px;
  border-top: 1px solid #e3e3e3;
  border-radius: 0;
  margin: 0;
  font-weight: 400;
} */
.modal .access select {
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
}
#proActMeasTooltip {
  opacity: 1;
  background-color: #fff;
  border-radius: 10px;
  filter: drop-shadow(0 0 5px #0000005e);
  color: #1E1E1E;
}


/* pulse animation */
button.recommendation {
  position: relative;
}
span.pulse-animation {
  position: absolute;
  width: 5px;
  height: 5px;
  /* background-color: var(--mv-color-red); */
  top: 10px;
  right: 10px;
}
span.pulse-animation::before,
span.pulse-animation::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--mv-color-red);
  border-radius: 10px;
}
span.pulse-animation::after {
  position: absolute;
  top: 0;
  left: 0;
  animation: 1s ease-in-out infinite circle-fade-after;
  -webkit-animation: 1s ease-in-out infinite circle-fade-after;
}
@keyframes circle-fade-after{50%{transform:scale(1);opacity:1}100%{transform:scale(4);opacity:0}}

/* svg */
.blu-svg path {
  stroke: var(--mv-color-primary);
}

/* App toolbar */
.toolbar .tools > button {
  height: 40px;
  position: relative;
}
.toolbar .tools > button img {
  max-width: 25px;
  margin: 0 auto;
}
.toolbar .tools:first-child > button {
  width: 40px;
  text-align: center;
  font-size: 28px;
}
.toolbar .tools:first-child > button::after {
  content: "";
  width: 100%;
  height: 6px;
  position: absolute;
  background-color: #4C4DFF;
  left: 0;
  bottom: -6px;
  border-radius: 10px;
  opacity: 0;
}
.toolbar .tools:first-child > button.active::after {
  opacity: 1;
}
.toolbar .tools:first-child > button svg {
  margin: 0 auto;
  color: #454953;
}
/* App toolbar end */

/* ag-grid utils */
.ag-root-wrapper .ag-cell-wrap-text {
  word-break: break-word;
}
.ag-root-wrapper .ag-cell-wrap-text .ag-cell-value {
  line-height: 25px;
}
.no-loading .ag-overlay-loading-center{
  display: none;
}
.ag-root-wrapper .deleteRovBtn {
  transition: ease-in-out 0.2s;
  color: #4c5059;
  opacity: 0;
}
.ag-root-wrapper .deleteRovBtn:hover {
  color: var(--mv-color-red);
}
.ag-root-wrapper .ag-row:hover .deleteRovBtn {
  opacity: 1;
}
/* ag-grid utils end */

/* mv toggle btn */
label.mv-toggle-btn {
  margin: 0;
  background-color: var(--mv-color-lightBlue);
  padding: 5px;
  border-radius: 5px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}
label.mv-toggle-btn:not(.checked) {
  padding-left: 60px;
}
label.mv-toggle-btn.checked {
  padding-right: 60px;
}
label.mv-toggle-btn span {
  display: block;
  background-color: var(--mv-color-primary);
  color: #fff;
  padding: 5px;
  width: 95px;
  text-align: center;
  border-radius: 5px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
label.mv-toggle-btn:not(.checked) span {
  background-color: #6768FB;
}

/* mv toggle btn end */

/* loading dots animation */
p .loadingDots {
  display: inline-flex;
  margin-left: 4px;
}
.relScrCell .loading,
.relScr .loading,
.loadingDots {
    display: flex;
    align-items: center;
    height: 17px;
}
.relScrCell .loading.hide,
.relScr .loading.hide,
.loadingDots.hide {
    display: none;
}
.relScrCell .loading .dot,
.relScr .loading .dot,
.loadingDots .dot {
    animation: waveAnimation 1s infinite ease-in-out;
    background-color: #000000 ;
    border-radius: 50%;
    height: 6px;
    margin-right: 3px;
    vertical-align: middle;
    width: 6px;
    display: inline-block;
}
.relScrCell .loading .dot:nth-child(1),
.relScr .loading .dot:nth-child(1),
.loadingDots .dot:nth-child(1) {
    animation-delay: 200ms;
}
.relScrCell .loading .dot:nth-child(2),
.relScr .loading .dot:nth-child(2),
.loadingDots .dot:nth-child(2) {
    animation-delay: 300ms;
}
.relScrCell .loading .dot:nth-child(3),
.relScr .loading .dot:nth-child(3),
.loadingDots .dot:nth-child(3) {
    animation-delay: 400ms;
}
.relScrCell .loading .dot:last-child,
.relScr .loading .dot:last-child,
.loadingDots .dot:last-child {
    margin-right: 0;
}
@keyframes waveAnimation {
    0% {
      transform: translateY(0px);
      background-color:#6CAD96;
    }
    28% {
      transform: translateY(-7px);
      background-color:#9ECAB9;
    }
    44% {
      transform: translateY(0px);
      background-color: #B5D9CB;
    }
}
/* loading dots animation */

.gridSelect {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #eee;
}
.formSelect {
  width: 100%;
  background-color: #eee;
  padding: 8px 15px;
  margin-top: 5px;
  border-radius: 5px;
  height: 37px;
}

.secondary-tab-btns .btn {
  border: 1px solid #B9CEF5;
  border-radius: 0;
  padding: 5px 20px;
  color: var(--mv-color-primary);
  background: transparent;
  min-height: auto;
}
.secondary-tab-btns .btn.active {
  background-color: var(--mv-color-lightBlue);
}
.secondary-tab-btns label {
  display: inline-flex;
  align-items: center;
  margin: 0 10px;
  gap: 0 5px;
}
.secondary-tab-btns label input {
  display: inline-block;
}

label.labelWithPercent {
  display: inline-flex;
  flex-direction: column;
  width: 160px;
}
label.labelWithPercent span {
  display: inline-flex;
  align-items: center;
  background-color: #eee;
  margin-top: 5px;
  border-radius: 5px;
  padding-right: 10px;
}
label.labelWithPercent span input{
  margin: 0;
  text-align: right;
  padding-right: 0;
}

#sidebar.progremView label[for="templateName"]{
  font-weight: 500;
}
#sidebar.progremView label[for="templateName"] input{
  font-weight: 400;
}
#sidebar.progremView .list {
  margin: 0 0 10px;
}
#sidebar.progremView .list p {
  font-weight: 500;
  margin-bottom: 5px;
}

.like-dislike button {
  font-size: 25px;
}
.like-dislike button svg {
  pointer-events: none;
}
/* .like-dislike button#dislike {
  transform: rotate(180deg);
} */
.like-dislike button.active svg path {
  fill: #4c4dff;
}

/* fullScreened */
.workingArea .card.fullScreened .ag-theme-alpine.mv-alternative-tbl,
.workingArea .basic-card.fullScreened .ag-theme-alpine.mv-alternative-tbl {
  height: calc(-94px + 100vh) !important;
  min-height: calc(94px - 100vh) !important;
}
.workingArea .card.fullScreened .chartDiv {
  height: calc(-84px + 100vh) !important;
}
.workingArea .card.fullScreened .dataStory,
.workingArea .card.fullScreened .tabs.relative,
.workingArea .card.fullScreened .secondary-tab-btns {
  display: none;
}
.workingArea .basic-card.fullScreened {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 5px 10px 0 #00000012;
  border-radius: 10px;
  position: relative;
}

svg [aria-label="Chart"] + g g[opacity="0.3"],
svg [aria-label="Chart"] + g g[opacity="0.4"] {
  display: none !important;
}

svg[color="white"] polyline {
  stroke: #fff;
}

.react-tooltip {
  z-index: 3;
}