.annotationPage {
  position: relative;
  width: 100%;
  height: 100vh;
}

.annotation {
  /* background-color: yellow; */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.annotation-text {
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px;
  position: absolute;
  top: 25px; /* Adjust as needed to position the text */
  left: 0;
  z-index: 1000;
  white-space: nowrap;
}

.annotation-form {
  position: absolute;
  height: 60px;
  background: #f3f4f6;
  border: 1px solid #ccc;
  padding: 8px;
  transform: translate(-50%, -50%);
  z-index: 1000;
  border-radius: 10px;
}

.textAreaBtnContainer {
  display: flex;
  align-items: center;
}

.textarea {
  /* position: relative; */
  font-size: 1rem;
  width: 100%;
  max-height: 40px;
  outline: none;
  color: #4b5563; /* text-gray-600 */
  background-color: #f3f4f6; /* bg-gray-100 */
  border-radius: 9999px; /* rounded-full */
  padding: 0.5rem 1.25rem 0.5rem 1.25rem; /* py-2 pl-5 pr-24 */
}

.commentBtn {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--mv-color-primary);
  border-radius: 20px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 5px;
}
.commentBtnContainer {
  /* position: absolute; */
  display: flex;
  gap: 8px;
}
