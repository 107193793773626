:root {
    --mv-navigation-height: 58px;
}

nav {
    background-color: #fbfbfb;
    border-bottom: 1px solid #cdd2dd;
}
nav .logo {
    font-family: 'Microgramma';
}

nav .items-group.apps .items {
    min-width: 190px;
}

.nav-items > ul > li {
    display: inline-flex;
}

.nav-items ul > li {
    cursor: pointer;
}

nav .dropdown .dd-menu :where(a, button) {
    display: flex;
    /* flex-wrap: wrap; */
    gap: 0 10px;
    align-items: center;
    padding: 0.25rem 1rem;
    width: 100%;
}
nav .dropdown .dd-menu a img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

nav .dropdown .dd-menu :where(a, button):hover {
    background-color: #E0EBFF;
}

/* .nav-items ul > li.notiItem a {
    position: relative;
    display: block;
} */

/* notification dropdown */
.nav-items ul > li.notifItem .dropdown > button,
.nav-items ul > li.colabItem .dropdown > button {
    position: relative;
    min-width: auto;
    min-height: auto;
}
.nav-items ul > li.notifItem .badge.count {
    width: 17px;
    height: 17px;
    top: -3px;
    right: -6px;
}
.nav-items ul > li.notifItem .dropdown .dd-menu {
    width: 500px;
    max-height: 600px;
    overflow: auto;
}
.nav-items ul > li.notifItem .notifList {
    padding: 20px 30px;
}
.nav-items ul > li.notifItem .notifList .mainBtns > button {
    display: inline-block;
    width: auto;
    padding: 3px 5px;
    color: var(--mv-color-primary);
    border-radius: 5px;
}
.nav-items ul > li.notifItem .notifList .mainBtns > button.active {
    background-color: #E0EBFF;
}

.nav-items ul > li.notifItem .notifList ul .single-notif {
    display: flex;
    flex-wrap: wrap;
    gap: 0 15px;
    align-content: center;
    margin-inline: -10px;
    padding: 10px;
    border-radius: 4px;
}
.nav-items ul > li.notifItem .notifList ul .single-notif.unread {
    background-color: #0000000a;
}
.nav-items ul > li.notifItem .notifList ul .single-notif:not(:last-child) {
    margin-bottom: 10px;
}
.nav-items ul > li.notifItem .notifList ul .single-notif .avatar {
    width: 85px;
    height: 85px;
}
.nav-items ul > li.notifItem .notifList ul .single-notif .avatar.icon {
    background-color: #ECECEC;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    color: #005a9c;
}
.nav-items ul > li.notifItem .notifList ul .single-notif .avatar.icon img {
    width: 30px;
    height: 30px;
}
.nav-items ul > li.notifItem .notifList ul .single-notif .avatar img {
    width: 100%;
    height: 100%;
}
.nav-items ul > li.notifItem .notifList ul .single-notif .text {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex: 1;
    justify-content: center;
}
.nav-items ul > li.notifItem .notifList ul .single-notif .text .title {
    font-weight: 500;
}
.nav-items ul > li.notifItem .notifList ul .single-notif .text .msg {
    font-size: 12px;
}
.nav-items ul > li.notifItem .notifList ul .single-notif .text .msg a {
    width: auto;
    display: inline-block;
    padding: 0;
    color: var(--mv-color-primary);
    background: transparent;
}
.nav-items ul > li.notifItem .notifList ul .single-notif .text .ref {
    font-size: 12px;
    font-weight: 300;
}
.nav-items ul > li.notifItem .notifList ul .single-notif .text time {
    font-size: 12px;
    color: var(--mv-color-primary);
    margin-top: 5px;
}
/* notification dropdown */

.dropdown .dd-menu.text-center button {
    justify-content: center;
}
.dropdown .dd-menu.bb-1 li:not(:last-child) button {
    border-bottom: 1px solid #efefef;
}

/* profile dropdown */
.dropdown.logout > .avatar {
    min-width: 35px;
    min-height: 35px;
}
.nav-items ul > li .avatar img {
    border-radius: 100%;
    width: 35px;
    height: 35px;
}
.dropdown.logout .dd-menu {
    max-height: 310px;
}
.dropdown.logout .dd-menu .username {
    display: flex;
    flex-wrap: wrap;
    gap: 0 10px;
    padding: 15px 15px 20px;
    border-bottom: 1px solid #efefef;
    align-items: center;
}
.dropdown.logout .dd-menu .username .pic {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
    box-shadow: 0 5px 5px 0 #0000003d;
}
.dropdown.logout .dd-menu .username .pic img {
    width: 100%;
    height: 100%;
}
.dropdown.logout .dd-menu .username .name-input {
    flex: 1;
    position: relative;
}
.dropdown.logout .dd-menu .username .name-input p {
    font-size: 18px;
    color: var(--mv-color-primary);
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.dropdown.logout .dd-menu .username .name-input #username {
    margin: 0;
    padding: 0 3px;
    height: 30px;
    background: transparent;
    position: absolute;
    top: -2px;
    left: 0;
    right: 0;
}
.dropdown.logout .dd-menu .username button.editUsername {
    padding: 0;
    width: 20px;
    font-size: 16px;
    height: 20px;
    background: transparent;
}
.dropdown.logout .dd-menu > p {
    padding: 10px 15px;
}
.dropdown.logout .dd-menu ul li button {
    font-size: 14px;
    justify-content: space-between;
}
.dropdown.logout .dd-menu ul li button svg {
    color: var(--mv-color-primary);
}
.dropdown.logout .dd-menu > button {
    background: transparent;
    color: inherit;
    font-size: 14px;
    font-weight: 400;
    padding: 15px 15px 10px;
    border-top: 1px solid #efefef;
    border-radius: 0;
    margin-top: 38px;
}
.dropdown.logout .dd-menu > button svg {
    font-size: 22px;
    color: var(--mv-color-reddish);
}
/* profile dropdown */


.inner-block.fullHeight {
    height: calc(100vh - 166px) !important;
}
.comments-log {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: left;
    gap: 5px 0;
}
.comments-log .comment {
    background-color: #f5f5f5;
    padding: 10px 20px;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.comments-log .comment .avatar {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--mv-color-primary);
    border-radius: 20px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 5px;
}
.comments-log .comment .info {
    display: flex;
    flex-wrap: wrap;
    gap: 0 20px;
    font-size: 12px;
}
.comments-log .comment .info .username {
    font-weight: 500;
}
.comments-log .comment .info .time {
    color: #00000091;
}
.comments-log .comment .desc {
    margin-top: 5px;
}

nav.loading {
    pointer-events: none;
}