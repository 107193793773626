.chat-backdrop {
    position: fixed;
    inset: 0;
    background-color: rgba(150, 149, 149, 0.50);
    z-index: 99;
    overflow-y: auto;
}
.chatMainContent {
    display: flex;
    flex-wrap: wrap;
    width: calc(100vw - 318px);
    height: calc(100vh - 300px);
    margin-left: auto;
    margin-right: 24px;
    background-color: #fff;
    margin-top: 252px;
    border-radius: 10px;
}
.chatMainContent .people-list {
    width: 280px;
    padding: 20px;
}
.chatMainContent .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
.chatMainContent .input-group .input-group-prepend {
    display: flex;
    margin-right: -1px;
}
.chatMainContent .input-group .input-group-prepend .input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #eeeeee;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.chatMainContent .input-group > .form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.chatMainContent .input-group > .form-control {
    position: relative;
    flex: 1 1;
    min-width: 0;
    margin: 0;
    background: transparent;
    border: 1px solid #ced4da;
    border-radius: .25rem;

}

.chatMainContent .people-list ul {
    height: calc(100vh - (379px + 0.5rem));
    overflow: auto;
}
.chatMainContent .people-list .chat-list li {
    padding: 10px 15px;
    list-style: none;
    border-radius: 5px;
    cursor: pointer;
}
.chatMainContent .people-list .chat-list li * {
    pointer-events: none;
}
.chatMainContent .people-list .chat-list li:hover,
.chatMainContent .people-list .chat-list li.active {
    background: var(--mv-color-lightBlue);
}
.chatMainContent .people-list .chat-list img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}
.chatMainContent .people-list .chat-list li .name {
    font-size: 15px;
}
.chatMainContent .people-list .status {
    color: #999;
    font-size: 13px;
}
.chatMainContent .people-list .status span {
    width: 8px;
    height: 8px;
    border-radius: 30px;
}
.chatMainContent .people-list .status.online span {
    background-color: var(--mv-completed);
}
.chatMainContent .people-list .status.offline span {
    background-color: var(--mv-color-red);
}

.chatMainContent .chat {
    width: 100%;
    max-width: calc(100% - 280px);
    border-left: 1px solid #eaeaea;
}
.chatMainContent .chat .chat-header {
    padding: 15px 20px;
    border-bottom: 2px solid #f4f7f6;
}
.chatMainContent .chat .chat-header .user-info img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
}

.chatMainContent .chat .chat-history {
    height: calc(100vh - (443px + 0.5rem));
    overflow: auto;
    padding: 20px 0;
}
.chatMainContent .chat .chat-history ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 20px 0;
    padding: 0 20px;
}
.chatMainContent .chat .chat-history ul .chat-node {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px 0;
}
.chatMainContent .chat .chat-history ul .chat-node .message-data .message-data-time {
    color: #434651;
    font-size: 12px;
}
.chatMainContent .chat .chat-history ul .chat-node .message {
    color: #444;
    padding: 18px 20px;
    font-size: 16px;
    border-radius: 7px;
    position: relative;
    width: fit-content;
}
.chatMainContent .chat .chat-history ul .chat-node .message::after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    top: -18px;
    pointer-events: none;
    border: 10px solid transparent;
    border-bottom-color: transparent;
    border-bottom-color: #fff;
}
.chatMainContent .chat .chat-history ul .chat-node.received .message::after {
    right: 10px;
    border-bottom-color: #e8f1f3;
}
.chatMainContent .chat .chat-history ul .chat-node.sent .message::after {
    left: 10px;
    border-bottom-color: #efefef;
}
.chatMainContent .chat .chat-history ul .chat-node.received {
    align-content: end;
}
.chatMainContent .chat .chat-history ul .chat-node.received .message-data {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0 10px;
    justify-content: end;
}
.chatMainContent .chat .chat-history ul .chat-node.received .message-data img {
    border-radius: 100%;
    width: 40px;
    height: 40px;
}
.chatMainContent .chat .chat-history ul .chat-node.received .message {
    background: #e8f1f3;
}
.chatMainContent .chat .chat-history ul .chat-node.sent .message {
    background: #efefef;
}

.chatMainContent .chat .chat-message {
    padding: 20px;
}