.dropdown {
    position: relative;
    cursor: auto;
}
.dropdown > button {
    /* min-width: 41px;
    min-height: 41px; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.dropdown > button.text {
    padding-right: 30px;
    min-width: 135px;
    background-color: var(--mv-color-lightBlue);
    color: var(--mv-color-primary);
}
.dropdown.tbtn > button {
    font-weight: 700;
    min-width: 120px;
    justify-content: space-between;
}
.dropdown .dd-menu {
    position: absolute;
    right: 0;
    left: auto;
    top: calc(100% + 5px);
    /* background-color: #eee; */
    background-color: #fff;
    border-radius: 10px;
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.10));
    /* min-width: 190px; */
    min-width: 250px;
    padding: .5rem 0;
    z-index: 9;
    max-height: 220px;
    overflow: auto;
    display: none;
}
.dropdown.show .dd-menu {
    display: block;
}
.dropdown .dd-menu ul li button {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px 15px;
    gap: 0 10px;
    font-size: 12px;
    width: 100%;
    border-radius: 0;
    color: #1e1e1e;
}
.dropdown .dd-menu ul li button svg {
    font-size: 18px;
}
.dropdown .dd-menu ul li button:not(:last-child) {
    border-bottom: 1px solid #6F6F6F63;
}

.dropdown:not(.blu-bg) .dd-menu ul li button.active {
    background-color: var(--mv-color-lightBlue);
}

.dropdown.blu-bg .dd-menu {
    background-color: #E0EBFF;
}
.dropdown.width-190 .dd-menu {
    /* max-width: 190px; */
    min-width: 190px;
}

.dd-menu label {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0 10px;
    padding: 0 15px 10px;
    border-bottom: 1px solid #a8a8a8;
    margin: 0 0 10px;
}
.dd-menu .btn {
    display: block;
    padding: 4px 10px;
    margin: 0 auto;
    background-color: #5657ff;
    color: #fff;
    border-radius: 20px;
    width: 100px;
    font-weight: 600;
}
.dd-menu  > button {
    width: fit-content;
    margin: 10px auto 0;
    display: block;
    padding: 7px 10px;
    background-color: #4C4DFF;
    min-width: 100px;
    color: #fff;
    border-radius: 30px;
    font-size: 12px;
    font-weight: 500;
}
.dropdown > button .chevron {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}
.dropdown > button .placeholder {
    color: #858585;
    font-weight: 400;
}
.dropdown > button span.options {
    display: block;
    width: calc(100% - 15px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dropdown.regionSelection > button {
    background-color: var(--mv-color-lightBlue);
    border-radius: 5px;
    padding-right: 30px;
    width: 135px;
}
.dropdown.regionSelection .dd-menu li {
    padding: 2px 0;
    margin: 3px 0;
    cursor: pointer;
}