.backdrop {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(31px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loading-data-box {
    background: #ffffff;
    box-shadow: 0px 7px 231px rgba(0, 0, 0, 0.25);
    /* border-radius: 10px;
    border: 5px solid;
    border-image: linear-gradient(90deg, #8efa4e 10%, #fef954 37.34%, #ee752f 62.48%, #ff0000 100%);
    border-image-slice: 1; */
    width: 600px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    padding-bottom: 30px;
    flex-direction: column;
}
.loading-data-box {
    /* --borderWidth: 4px; */
    position: relative;
    border-radius: 4px;
}
.loading-data-box img {
    width: 50px;
    height: auto;
    margin: 0 0 10px 0;
}
.loading-data-box p, .loading-data-box span {
    text-align: center;
    font-weight: 700;
    margin-bottom: 0;
}
.loading-data-box .quote, .loading-data-box a {
    font-size: 1.3rem;
    font-weight: 500;
    margin: 20px 0 0 0;
    max-width: 450px;
    color: black;
    text-decoration: none;
}

.loading-data-box::after {
    content: "";
    position: absolute;
    top: calc(-1 * 4px);
    left: calc(-1 * 4px);
    height: calc(100% + 4px * 2);
    width: calc(100% + 4px * 2);
    background: linear-gradient( 60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82 );
      background-size: auto;
    border-radius: calc(2 * 4px);
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
}

.loading-data-box .spinner {
    position: relative;
    margin-top: 10px;
}

.loading-data-box .spinner span {
    position: absolute;
    inset: -5px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

/* loader */
.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}
.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #4c4dff;
    margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
    opacity: 0.2;
}
.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
    opacity: 0.3;
}
.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
    opacity: 0.4;
}
.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
    opacity: 0.5;
}
.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
    opacity: 0.6;
}
.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
    opacity: 0.7;
}
.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
    opacity: 0.85;
}
.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}
@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
/* loader */

@keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
